<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{ currentTable }}</h4>
          <div class="row">
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select class="select-primary pagination-select" v-model="pagination.perPage" placeholder="Por página">
                <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"></el-option>
              </el-select>
              <div class="d-flex align-items-center">
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Procurar item" v-model="searchQuery" aria-controls="datatables"></el-input>
              </div>
            </div>
            <div class="col-12">
              <el-table :data="queriedData.filter(data => !searchQuery || data.unity.toLowerCase().includes(searchQuery.toLowerCase()))" v-if="total > 0">
                <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label"></el-table-column>
                <el-table-column width="108" v-if="total > 0" align="center" label="Ações">
                  <template v-slot="props">
                    <el-button type="info" icon="tim-icons icon-check-2" plain circle @click="edit(props.$index, props.row)" size="small"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category table-footer" v-if="total > 0">
                Exibindo de {{ from + 1 }} à {{ to }} de {{ total }} registros
              </p>
              <p class="card-category table-footer" v-if="total === 0">
                Nenhum registro encontrado.
              </p>
            </div>
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total" v-if="total > 0"></base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Button } from 'element-ui'
import { BasePagination } from '@/components'
import Fuse from 'fuse.js'
import swal from 'sweetalert2'
export default {
  data () {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        total: 0
      },
      searchQuery: '',
      propsToSearch: [],
      tableColumns: [],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    }
  },
  methods: {
    edit (index, row) {
      return this.$router.push(`/transaction-approve/${row.id}`)
    },
    handleActivate (index, row) {
      swal.fire({
        title: 'Você tem certeza?',
        text: `Você está prestes a ativar "${row.unity}".`,
        showCancelButton: true,
        confirmButtonText: 'Sim, eu quero ativar',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.value) {
          swal.fire({
            title: 'Ativando',
            text: `Aguarde, estamos ativando "${row.unity}".`,
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.activateRow(row).then(status => {
            if (status) {
              swal.fire({
                title: 'Ativando!',
                text: `Você ativou "${row.unity}"`,
                showConfirmButton: false,
                timer: 1000
              })
            } else {
              swal.fire({
                title: 'Error!',
                text: `Ocorreu um erro ao ativar "${row.unity}"`,
                denyButtonText: 'Ok',
                showDenyButton: true,
                showConfirmButton: false
              })
            }
          })
        }
      })
    },
    handleDelete (index, row) {
      swal.fire({
        title: 'Você tem certeza?',
        text: `Você está prestes a inativar "${row.unity}".`,
        showCancelButton: true,
        confirmButtonText: 'Sim, eu quero inativar',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.value) {
          swal.fire({
            title: 'Inativando',
            text: `Aguarde, estamos inativando "${row.unity}".`,
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.deleteRow(row).then(status => {
            if (status) {
              swal.fire({
                title: 'Inativado!',
                text: `Você inativou "${row.unity}"`,
                showConfirmButton: false,
                timer: 1000
              })
            } else {
              swal.fire({
                title: 'Error!',
                text: `Ocorreu um erro ao inativar "${row.unity}"`,
                confirmButtonText: 'Ok',
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    async deleteRow (row) {
      let status
      try {
        status = (await this.$http.delete(`unities?id=${row.id}&status=0`)).data
          .data.status
      } catch (e) {
        status = e.response.status
      }
      if (status === 200) {
        const indexToDelete = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        )
        if (indexToDelete >= 0) {
          this.tableData[indexToDelete].status = 'Inativo'
          // this.tableData.splice(indexToDelete, 1);
        }
        this.getData()
        return true
      }
      return false
    },
    async activateRow (row) {
      let status
      try {
        status = (await this.$http.delete(`unities?id=${row.id}&status=1`)).data
          .data.status
      } catch (e) {
        status = e.response.status
      }
      if (status === 200) {
        const indexToDelete = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        )
        if (indexToDelete >= 0) {
          this.tableData[indexToDelete].status = 'Inativo'
          // this.tableData.splice(indexToDelete, 1);
        }
        this.getData()
        return true
      }
      return false
    },
    async getData () {
      const url = 'transactions/approval'
      const { data } = await this.$http.get(url)
      // const { data } = [{}]
      this.tableData = data.data
      this.tableColumns = [
        {
          prop: 'date_formated',
          label: 'Data solicitação',
          minWidth: 40
        },
        {
          prop: 'user_account.user.name',
          label: 'Gerador',
          minWidth: 150
        },
        {
          prop: 'ammount_formated',
          label: 'Valor',
          minWidth: 40
        },
        {
          prop: 'bank_account.bank.name',
          label: 'Banco',
          minWidth: 40
        }
      ]

      this.propsToSearch = ['user_account.user.name', 'ammount_formated', 'bank_account.bank.name']
    }
  },
  mounted () {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.3
    })
    this.getData()
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery (value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    }
  },
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button
  },
  computed: {
    queriedData () {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length
    },
    currentTable () {
      const msg = `lista de ${this.$route.name}`
      return msg.toUpperCase()
    }
  }
}
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}

p.table-footer {
  text-transform: none;
}

.btn-new {
  height: 2.5rem;
}
</style>
